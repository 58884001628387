@import url('https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap');

body * {
  font-family: 'ZCOOL XiaoWei', sans-serif;
}

.App {
  padding: 0 2rem;
}

button {
  cursor: pointer;
  border-radius: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* NavBar Style */

.NavBar * {
  text-decoration: none;
}

.NavBar {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10%;
  margin-top: 1.5%;
}

.geni-e_logo {
  width: 5%;
}

.Return-Home {
  position: relative;
  width: 145px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  border: 2px solid #57e0e9;
  background-color: #57e0e9;
}

.Return-Home svg {
  color: #fff;
  height: 25px;
  width: 25px;
}

/* Container for Login and image Styling */

.grid-container {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}

.logins {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login-image img {
  position: relative;
  width: unset;
  height: unset;
}

/* Login form */

.logins .EmailLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EmailLogin input {
  height: 50px;
  width: 345px;
  border-radius: 8px;
  border: solid black 2px;
  margin-top: 15px;
  line-height: 6rem;
  text-indent: 2%;
}

button.visPSW {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: unset;
  color: black;
  padding: 0px;
  margin: 0px;
  bottom: 38px;
  left: 140px;
  border: none;
  font-size: 16px;
}

.signupmode {
  display: flex;
  position: relative;
  align-items: center;
  gap: 12rem;
  bottom: 0.7rem;
}

.forgot-password {
  position: relative;
  background: none;
  border: none;
  padding: 0px;
}

.Nw-user {
  position: relative;
  background: none;
  border: none;
  padding: 0px;
}

.SGN-IN {
  margin-left: unset;
  width: 345px;
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 8px;
  border: 2px solid #57e0e9;
  font-size: 20px;
  background-color: #57e0e9;
}

.line-with-text {
  display: flex;
  align-items: center;
  min-width: 30%;
  flex-direction: row;
  margin: 32px 0;
}

.line-with-text span {
  flex-grow: 1;
  height: 1px;
  background: black;
  min-width: 110px;
}

.line-with-text .text {
  padding: 0 10px;
  font-size: 16px; 
}

.SocialBTN {
  display: flex;
}

.google-btn {
  margin-bottom: 15px;
  border: none;
  background: none;
}

.google {
  transform: scale(0.7);
}

.error-message {
  background: red;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
  width: 353px;
}

.success-message {
  background: green;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
  width: 353px;
}

/* Dashboard Styling */

.MainContainer {
  display: flex;
  flex-direction: row;
}

.SideBarSection {
  height: fit-content;
  margin-top: 80%;
  position: fixed;
}

.Sidebar {
  display: flex;
  flex-direction: column; 
  margin-top: -33px;
}

.social.logos {
  width: 25px;
  height: 25px;
  margin-block: 10px 20px;
  writing-mode: horizontal-tb;
}

.SideButton {
  height: 4vh;
  background: #ffffff;
  border: 2px solid black;
  width: 100%;
  margin: auto;
  font-size: large;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.Sidebar h3 {
  text-align: center;
  margin-bottom: -85%;
}

.close-menu {
  display: flex;
  justify-content: flex-end;
  width: 48px;
}


.hamburger {
  font-size: xxx-large !important;
  color: #F4743B;
  cursor: pointer;
  height: auto;
  position: auto;
}

.star {
  color: #fc8a1e;
}

.test {
  width: 100%;
  border: 2px solid rgb(248, 120, 0);
  height: auto;
  border-radius: 15px;
  padding: 25%; /* Increase the padding as needed to move content up */
  background: #ffb9805b;
  margin-top: 8%; /* Adjust the margin-top to move the section up */
}

.top {
  display: flex;
  flex-direction: row;
  gap: 10rem;
  justify-content: center;
  margin-bottom: 5%;
}

.logos {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: -15%;
}

.social.logos {
  width: 90%;
  height: 40px;
}

/* Navbar */

.nav {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 11%;
  justify-content: space-between;
  position: fixed;
  width: 96dvw;
  z-index: 1;
}

.creditInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.NoC {
  font-size: 62px;
  color: #53d7df;
  margin-top: 68px; /* Adjust this margin to move it lower */
}

img.MainLogo {
  height: 50px;
}



.creditText {
  display: flex;
  flex-direction: column;
  margin-top: 0.1px; /* Adjust this margin as needed */
  gap: 0;
  
}

.creditText h3 {
  margin-bottom: -10px; /* Adjust this margin to reduce the gap between h3 and p */
  font-size: 20px;
}


p.addMore {
  font-size: smaller;
  color: grey;
  cursor: pointer;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
}


/* Generated Images Styling */
/* Upload Section */

.MainSection {
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto auto;
  width: fit-content;
  margin-top: 1200px;
}

.MainUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Uploadbox {
  margin-top: 50%;
}

img.UploadedImage {
  max-width: 1030px;
  max-height: 605px;
}

.Background {
  background-color: #000000;
  width: 350px;
  height: 550px;
  border: 2px solid #000000;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  margin-bottom: 15px;
}

.LabelUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #fffffff2;
  border-radius: 25px;
  min-height: 50px;
  height: auto;
  background: #ffffffad;
  margin-top: 3%; /* Adjust this value to move it down */
  margin-bottom: 10%;
}

.LabelUpload img {
  width: 350px;
}

.LabelUpload p {
  color: #858585;
}

.loading { /* loading gif*/
  width: 40px;
  height: 40px;
  margin-left: -13%;
  margin-top: 2%;
}

.Search {
  width: 4.3%;
  cursor: pointer;
  margin-left: -11.2%;
  opacity: 50%;
  margin-top: 2%;
}

.Search_Grey {
  filter: grayscale(1);
  margin-left: -5%;
}

.Prompt { /* loading prompt text box*/
  display: flex;
  align-items: center;
  margin-left: -4.5%;
}

.non-loading {
  margin-left: 10%;
  margin-top: -0.2%;
}

[contentEditable=true]:empty:not(:focus):before{
  content:attr(data-text)
}

.prompttext {
  border: 1px solid black;
  border-radius: 10px;
  margin: 0 5 0 5%;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow-wrap: anywhere;
  width: min-content; /* Auto-scale width based on content */
  height: 20%; /* Responsive height as a percentage of LabelUpload's height */
}

.TextBox {
  display: flex;
  flex-direction: row; /* Change to row to align icon and text horizontally */
  align-items: center;
  justify-content: center;
  text-align: left;
  width: auto; /* Responsive width based on viewport width */
  min-width: 700px!important;
  height: 25px!important; /* Responsive height based on font size */
  border: 1px solid black;
  border-radius: 15px;
  margin: 0 5%; /* Adjust left margin as needed */
  /* Adjust margin as needed */
  font-size: 16px; /* Responsive font size */
  resize: none;
  margin-top: 2%;

}

.promptHelp {
  margin-top: 40em; /*Adjust this value to bring the text higher, closer to the textbox */
  position: absolute; /* Use absolute positioning to take it out of the normal flow*/
  left: 50%; /*Center horizontally relative to its closest positioned ancestor*/
  transform: translateX(-50%); /*Offset its own width to ensure true centering */
  text-align: center; /* Ensure the text inside is centered as well */
  width: 100%; /* Make the div full width of the container */
}

.promptHelp h4 {
  font-size: 15px;
  color: #000000; /* Maintain the text color as black */
  font-weight: normal;
}

.promptHelp a {
  font-weight: bold;
  color: #ffae00; /* Maintain the text color as black */
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.085);
  text-align: center;
  z-index: 999;
}

#popupImg {
  max-width: 80%;
  max-height: 80%;
  margin-top: 10%;
}

/* You can style the popupImage div and the image (popupImg) as per your design requirements. */

#popupImage.active {
  display: block;
}

.error {
  margin-top: -5.3em; /*Adjust this value to bring the text higher, closer to the textbox */
  position: absolute; /* Use absolute positioning to take it out of the normal flow*/
  left: 50%; /*Center horizontally relative to its closest positioned ancestor*/
  transform: translateX(-50%); /*Offset its own width to ensure true centering */
  text-align: center; /* Ensure the text inside is centered as well */
  width: 100%; /* Make the div full width of the container */
  font-size: 14px;
  color:orange;
}
.error2 {
  margin-top: -1.8em; /*Adjust this value to bring the text higher, closer to the textbox */
  position: absolute; /* Use absolute positioning to take it out of the normal flow*/
  left: 50%; /*Center horizontally relative to its closest positioned ancestor*/
  transform: translateX(-50%); /*Offset its own width to ensure true centering */
  text-align: center; /* Ensure the text inside is centered as well */
  width: 100%; /* Make the div full width of the container */
  font-size: 14px;
  color:orange;
}

/* Gallery Styling */

.GalleryUpload-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 2rem;
  margin-top: 3%;
}

.MainGallerySection {
  position: relative;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin: auto;
  margin-top: 8%;
  right: 3.5%;
}

.MainGallerySection h1 {
  color: #57e0e9;
}

.MainGallerySection h2 {
  color:rgb(0, 0, 0)
}

.MainGalleryContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.GalleryClickable-image {
  width: 260px;
  height: 220px;
  object-fit: cover;
  border: 2px solid #57e0e9;
  border-radius: 25px;
  cursor: pointer;
}

.Generated_Images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Generated_Images h1 {
  margin-bottom: 40px;
}

.img-without-alt {
  margin-bottom: 2%;
}

/* Credits */

.display-block {
  display: block;
  z-index: 1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.477);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: 2px solid black;
  border-radius: 5%;
  width: 20rem;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10% auto;
  height: fit-content;
  align-items: center;
}

.tips-modal-content {
  border: 2px solid black;
  border-radius: 5%;
  width: 0px;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10% auto;
  height: fit-content;
  align-items: center;
}

span.close { /*the box cross that the modal goes in*/
  position: relative;
  left: 8.6rem;
  font-size: xx-large;
  color: red;
  cursor: pointer;
}

.same-size-textbox {
  width: 30%; /* Responsive width based on viewport width */
  height: 50%; /* Responsive height based on font size */
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
}

/* Styles for the "here" link within the modal */

.addMore {
  text-decoration: underline;
  color: rgb(42 129 150);
  cursor: pointer;
}

.addMoreCredits {
  cursor: pointer;
  text-shadow: rgb(0, 255, 221) 1px 0 10px;
}


.modal-content section {
  margin-bottom: 10%;
}

/* Clicked Image */

.generic-modal-content {
  border: 2px solid black;
  border-radius: 5%;
  width: fit-content;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  height: fit-content;
  padding: 1rem 4rem;
}

.generic-modal-content span {
  position: relative;
  left: 18rem;
  bottom: 1rem;
  font-size: xxx-large;
  color: red;
}

.modal-image {
  width: 512px;
  height: 512px;
}

.productSubmit {
  background: #020231;
  color: #fff;
  width: 5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
}

.save-button {
  background: #020231;
  color: #fff;
  width: 9rem;
  height: 4rem;
  font-size: larger;
  border-radius: 0.5rem;
}

.ModifyPrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% 0;
}

.ModifyPrompt h2 {
  margin-bottom: 2%;
}

.LabelUpload, .img-without-alt {
  cursor: pointer;
}

.ModifyPrompt .TextBox {
  height: 30px;
  margin-top: 2%;
}

.arrow img {
  height: 2rem;
}

.arrow {
  position: absolute;
  animation: jumpInfinite 1.5s infinite;
  right: 24%;
  height: 1.5rem;
  width: auto;
  opacity: 0.3;
}

@keyframes jumpInfinite {
  0% {
      margin-top: 1%;
  }
  50% {
      margin-top: -2px ;
  }
  100% {
      margin-top: 1%;
  }
}


@media only screen and (min-width: 1024px) {

  /* Login Page */

  .login-image img {
    width: 500px;
  }

  /* Main Page */

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 2%;
  }

  .display-none {
    display: none;
  }

  input.Upload {
      display: none;
  }

  .Sidebar h3 {
      margin-top: 80%;
  }

  .SideBarSection {
    margin-top: 15%;
  }

  .MainSection {
    margin-top: 16%;

  }

  .UploadBackground {
      width: 600px;
      margin: auto;
  }

  .Background {
    width: 85dvw;

  }

  img.UploadedImage {
      max-width: 100vh;
  }

  img.UploadedImageMenu {
    max-width: 55dvw;
  }

  .LabelUpload {
    width: 85dvw;
  }

  .LabelUploadMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #d7d7d7f2;
    border-radius: 25px;
    width: 60dvw;
    height: 605px;
    background: #FAFAF5;
  }
  
  .LabelUploadMenu img {
    width: 300px;
  }
  
  .LabelUploadMenu p {
    color: #858585;
  }

  .BackgroundMenu {
    background-color: #FAFAF5;
    width: 60dvw;
    height: 605px;
    border: 2px solid #E6E6E6;
    display: flex;
    justify-content: center;
    border-radius: 25px;
  }

  .Open {
    margin-left: 20rem;
  }

  .SmUpload {
    width: 60dvw;
  }

  .SmBackGround {
    width: 60dvw;
  }

  .SmUPIM {
    max-width: 60vh !important;
  }

  /* Gnerated Images */

  .img-without-alt {
    margin-bottom: 2%;
    width: 400px;
  }

  .ImOpen {
    grid-template-columns: unset !important;
  }

  /* Gallery */

  .GalleryUpload-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 2rem;
    margin-top: 3%;
  }

  .Images {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }

}

@media only screen and (min-width: 1440px) {

  /* Login Page */

  .login-image img {
    width: 550px;
  }

  /* Main Page */

  .display-none {
    display: none;
  }

  input.Upload {
      display: none;
  }

  .Sidebar h3 {
      margin-top: 80%;
  }

  .UploadBackground {
      width: 900px;
  }

  .Background {
      width: 1030px;
  }

  img.UploadedImage {
      max-width: 1030px !important;
  }

  .SideBarSection {
    margin-top: 12%;
  }

  img.UploadedImageMenu {
    max-width: 1030px;
  }

  .BackgroundMenu {
    width: 1030px;
  }

  .LabelUploadMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #d7d7d7f2;
    border-radius: 25px;
    width: 1030px;
    height: 605px;
    background: #FAFAF5;
  }

  .LabelUpload {
    width: 1030px;
  }

  .Open {
    margin-left: 20rem;
  }

  /* Generated Images */

  .img-without-alt {
    margin-bottom: 2%;
    width: 512px;
  }

  .ImOpen {
    grid-template-columns: unset !important;
  }  

  /* Gallery */

  .GalleryUpload-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 2rem;
    margin-top: 3%;
  }

  .Images {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }

}

@media only screen and (min-width: 1500px) {

  /* Login Page */

  .login-image img {
    width: 550px;
  }

  /* Main Page */

  .display-none {
    display: none;
  }

  input.Upload {
      display: none;
  }

  .SideBarSection {
    margin-top: 10%;
  }

  .Sidebar h3 {
      margin-top: 80%;
  }

  .UploadBackground {
      width: 900px;
  }

  .Background {
      width: 1030px;
  }

  img.UploadedImage {
      max-width: 1030px;
  }

  img.UploadedImageMenu {
    max-width: 1030px;
  }

  .BackgroundMenu {
    background-color: #FAFAF5;
    width: 1030px;
    height: 605px;
    border: 2px solid #E6E6E6;
    display: flex;
    justify-content: center;
    border-radius: 25px;
  }

  .LabelUploadMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #d7d7d7f2;
    border-radius: 25px;
    width: 1030px;
    height: 605px;
    background: #FAFAF5;
  }

  .Open {
    margin-left: 0rem;
  }

  .MainSection {
    margin-top: 10%;
  }

  /* Generated Images */

  .img-without-alt {
    margin-bottom: 2%;
    width: 512px;
  }

  .Images {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }

  .ImOpen {
    grid-template-columns: 50% 50% !important;
  }

}